import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "./router";
import store from "./store";
import ApiService from "./common/api/api.service";
import "./registerServiceWorker";
import wb from "@/registerServiceWorker";

// import "./plugins/VuetifyMoney.js";

Vue.prototype.$workbox = wb;
Vue.config.productionTip = false;

ApiService.init();

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric"
};

Vue.filter("formatDate", function (value) {
  if (!value) return "";
  return new Date(value).toLocaleDateString("pt-br", options);
});

Vue.filter("formatDate0", function (value) {
  if (!value) return "";
  return value
    .split("-")
    .reverse()
    .join("/");
});

Vue.filter("formatDate2", function (value) {
  if (!value) return "";
  let dt = new Date(value);
  return (
    dt.toLocaleDateString("pt-br") +
    " " +
    dt.toLocaleTimeString("pt-br", {
      hour: "numeric",
      minute: "numeric",
      hour12: false
    })
  );
});

Vue.filter('activeText', function (value) {
  return value ? "Ativo":"Inativo";
})

Vue.filter("money", function (value) {
  if (typeof (value) === "undefined" || value === null || value === "") return "";
  return new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL"
  }).format(value);
});

Vue.directive('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.directive('uppercase', {
  bind(el, _, vnode) {
      el.addEventListener('keyup', (e) => {
          e.target.value = e.target.value.toUpperCase()
          vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
      })
  }
})

Vue.prototype.$log = console.log;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
