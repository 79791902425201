<template>
  <v-app class="bk">
    <v-system-bar app dark window color="#3D1159">
      <v-spacer></v-spacer>
      <a href="https://www.lyndus.com" target="blank"> Ir para o site </a>
    </v-system-bar>

    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col xs="12" sm="12" md="12">
          <v-card :elevation="24" class="mx-auto" max-width="400">
            <v-form
              v-model="loginValid"
              ref="formLogin"
              v-on:submit.prevent="loginSubimit(cnpj, username, password)"
            >
              <v-card-text>
                <div
                  class="d-flex flex-column justify-space-between align-center"
                >
                  <v-img
                    src="@/assets/logo.jpg"
                    class="mt-2"
                    max-height="150"
                    max-width="300px"
                  ></v-img>
                </div>
                <v-alert :value="true" type="warning" outlined v-if="errors">{{
                  errors
                }}</v-alert>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline">
                      Acesse o Painel e faça tudo ser melhor
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    name="cnpj"
                    type="text"
                    label="CNPJ"
                    v-model="cnpj"
                    v-mask="['##.###.###/####-##']"
                    :rules="cnpjRules"
                    placeholder="Digite o seu CNPJ"
                    outlined
                    required
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    name="username"
                    type="text"
                    label="Usuário"
                    v-model="username"
                    :rules="usernameRules"
                    placeholder="Digite o seu usuário de acesso"
                    outlined
                    required
                  ></v-text-field>
                </v-list-item>

                <v-list-item>
                  <v-text-field
                    name="password"
                    type="password"
                    label="Senha"
                    v-model="password"
                    :rules="passwordRules"
                    placeholder="Digite a sua senha"
                    outlined
                    required
                  ></v-text-field>
                </v-list-item>

                <div class="d-flex w-100" style="justify-content: center">
                  <v-btn
                    :to="{ name: 'RecoverPass' }"
                    text
                    small
                    color="indigo"
                  >
                    Esqueci minha senha
                  </v-btn>
                </div>
                <br />
              </v-card-text>

              <v-card-actions>
                <v-btn type="submit" block color="#6D59E8" dark large>
                  <v-icon left dark>mdi-login</v-icon>
                  Entrar</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-footer color="#3D1159" inset dark class="py-5 px-2 text-center">
      <v-spacer></v-spacer>
      <a
        href="https://goo.gl/maps/LBbJmfUr5atJteAVA"
        target="_blank"
        class="align-center"
      >
        Lyndus S/A, CNPJ 43.616.904/0001-59
        <br />Rua Padre Anchieta, 2348 - Bigorrilho, Curitiba - PR, CEP
        80730-000
      </a>

      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<style scoped>
body {
  min-height: 600px;
}
.bk {
  background-image: url("~@/assets/background-bs.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.v-system-bar a {
  font-family: Arial, Helvetica, sans-serif;
  display: block;
  font-size: 10px;
  color: #ffffff;
  margin: 0px 0px 10px;
  text-decoration: none;
  line-height: 10px;

  float: left;
  display: block;
  height: 8px;
  margin-top: 13px;
  margin-right: 8px;
  padding-right: 8px;
}

.v-system-bar .last {
  border-right: none;
}

.v-system-bar .on {
  color: #ffffff;
}

.v-footer a {
  font-family: "Montserrat";
  font-size: 12px;
  color: #ffffff;
  line-height: 15px;
  text-decoration: none;
}

.v-alert {
  margin-top: 16px;
}

.v-list-item .v-list-item__subtitle,
.v-list-item .v-list-item__title {
  font-size: 12px !important;
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat, sans-serif !important;
  margin-bottom: -15px;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import { mask } from "vue-the-mask";

import store from "@/store";
// import { latLng } from "leaflet";

import { LOGIN } from "@/store/actions/auth.type";
import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";
import { validarCNPJ } from "@/common/cnpj.service";

export default {
  name: "Login",
  directives: {
    mask,
  },
  props: {
    source: String,
  },

  // components: {},
  // async beforeRouteUpdate(to, from, next) {
  //   // Reset state if user goes from /editor/:id to /editor
  //   // The component is not recreated so we use to hook to reset the state.
  //   await store.commit(CHANGE_PROGRESS, false);
  //   return next();
  // },
  async beforeRouteEnter(to, from, next) {
    Promise.all([store.commit(CHANGE_PROGRESS, false)]).then(() => {
      next();
    });
  },
  data: () => ({
    loginValid: false,
    cnpj: "",
    username: "",
    password: "",
    isLoading: false,
    drawer: true,
    menu: 4,
    cnpjRules: [
      (v) => !!v || "O CNPJ é necessário",
      (v) => validarCNPJ(v) || "Informe um CNPJ válido.",
    ],
    usernameRules: [
      (v) => !!v || "O seu codigo de usuário é necessário",
      (v) => (v && v.length > 2) || "Login deve conter no mínimo 3 caracteres!",
    ],

    passwordRules: [
      (v) => !!v || "A Senha é necessária",
      // v =>
      //   /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      //   "Insura um e-mail válido"
    ],
  }),
  methods: {
    loginSubimit(cnpj, username, password) {
      if (this.$refs.formLogin.validate()) {
        this.$store
          .dispatch(LOGIN, {
            cnpj,
            username,
            password,
          })
          .then(() => {
            //(data) => {
            this.$router.push({
              name: "categories",
            });
          });
      }
    },
    loginFailed() {
      this.error = "O Login falhou!";
      this.$store.dispatch("logout");
      delete localStorage.token;
      this.snackbar.snackbar = true;
      this.snackbar.color = "error";
      this.snackbar.text = "Usuario ou senha inválidos.";
    },
    reset() {
      this.$refs.formLogin.reset();
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["inProgress", "snackbar"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
