import { CategoryService } from "@/common/api/category.service";
import {
  CATEGORY_CREATE,
  CATEGORY_EDIT,
  // CATEGORY_DELETE,
  CATEGORIES_GET,
  CATEGORY_SEARCH,
  CATEGORY_GET, CATEGORY_ACTIVE, CATEGORY_INACTIVE, CATEGORY_DELETE,
} from "./actions/categories.type";

import {
  SET_CATEGORY,
  SET_CATEGORIES,
  SET_CATEGORIES_SEARCH,
  SET_CATEGORY_PAGINATION,

  CATEGORIES_RESET_STATE,
  CATEGORY_RESET_STATE,
  SET_CATEGORY_ACTIVE,

} from "./mutations/categories.type";

import { CHANGE_PROGRESS } from "./mutations/mutations.type";

const initialState = () => {
  return {
    category: {
      id: 0,
      name: "",
      value: 0,
      active: null,
    },
    categories: [
      {
        id: 0,
        clientID: 0,
        name: "",
        value: 0,
      },
    ],

    categoryPagination: {
      search: "",
      itemsPerPage: 10,
      orderBy: "",
      sortDesc: false,
      status: 1,
    },
    categoriesSearch: {
      activePage: 1,
      totalPages: 1,
      totalItems: 1,
      items: []
    },
  };
};

export const state = initialState();

const getters = {
  categories: (state) => {
    return state.categories;
  },
  category: (state) => {
    return state.category;
  },
  categoryPagination: (state) => {
    return state.categoryPagination
  },
  categoriesSearch: (state) => {
    return state.categoriesSearch
  }
};

export const mutations = {
  [SET_CATEGORIES](state, data) {
    state.categories = data;
  },
  [SET_CATEGORY](state, data) {
    state.category = data;
  },
  [SET_CATEGORIES_SEARCH](state, data){
    state.categoriesSearch = data
  },
  [SET_CATEGORY_PAGINATION](state, data) {
    state.categoriesSearch.activePage = data.page
    state.categoryPagination.itemsPerPage = data.itemsPerPage
    state.categoryPagination.orderBy = data.orderBy
    state.categoryPagination.sortDesc = data.sortDesc
  },
  [SET_CATEGORY_ACTIVE](state, data){
    state.category.active = data
  },
  [CATEGORIES_RESET_STATE](state) {
    Object.assign(state, initialState().categories);
  },
  [CATEGORY_RESET_STATE](state) {
    Object.assign(state, initialState());
  }
};

export const actions = {
  async [CATEGORIES_GET]({ commit }) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await CategoryService.list();
    commit(SET_CATEGORIES, data);
    commit(CHANGE_PROGRESS, false);
  },
  async [CATEGORY_SEARCH]({ commit }) {
    commit(CHANGE_PROGRESS, true)
    const {data} = await CategoryService.search(
      state.categoriesSearch.activePage,
      state.categoryPagination.itemsPerPage,
      state.categoriesSearch.active,
      state.categoryPagination.search,
      state.categoryPagination.orderBy,
      state.categoryPagination.sortDesc)
    commit(SET_CATEGORIES_SEARCH, data)
    commit(CHANGE_PROGRESS, false)
  },

  [CATEGORY_EDIT]({ state }) {
    return  CategoryService.update(state.category);
  },
  [CATEGORY_CREATE]({ state }) {
    return CategoryService.create(state.category);
  },
  async [CATEGORY_GET]({ commit }, slug) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await CategoryService.get(slug);
    commit(SET_CATEGORY, data);
    commit(CHANGE_PROGRESS, false);
  },
  [CATEGORY_ACTIVE](_, id) {
    return CategoryService.active(id)
  },
  [CATEGORY_INACTIVE](_, id) {
    return CategoryService.inactive(id)
  },
  [CATEGORY_DELETE]({state}) {
    return CategoryService.delete(state.category.id)
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
