const categoriesRoute = [
  {
    path: "/categories",
    name: "categories",
    component: () => import("@/views/categories/Categories"),
  },
  {
    path: "/categories/add",
    name: "categoriesAdd",
    component: () => import("@/views/categories/CategoriesEditCreate"),
  },
  {
    path: "/categories/:id",
    name: "category",
    component: () => import("@/views/categories/Category"),
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    },
  },
  {
    path: "/categories/:id/edit",
    name: "categoriesEdit",
    component: () => import("@/views/categories/CategoriesEditCreate"),
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    },
  },
];

export default categoriesRoute;
