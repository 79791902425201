const initialState = () => {
  return {
    request: {
      username: "", //001
      cnpj: "" //61655196000147
    },
    confirm: {
      key: ""
    },
    recovery: {
      key: "",
      newPassword: "",
      passwordRecoveryCode: ""
    },
    recoveryCode: {
      key: "",
      email: ""
    }
  };
};

export const state = initialState();
