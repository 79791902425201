import ApiService from "@/common/api/api.service";

export const UserService = {

  list_permissions(slug) {
    ApiService.setHeader()
    return ApiService.get('permissions', slug)
  },
  list() {
    ApiService.setHeader()
    return ApiService.get('user')
  },

  search(page = 1, itemsPerPage = 10, active = true, search = "", orderBy = "", sortDesc = "") {
    ApiService.setHeader()
    return ApiService.get(`user?page=${page}&itemsPerPage=${itemsPerPage}&active=${active}&search=${search}&orderBy=${orderBy}&sortDesc=${sortDesc}`)
  },

  get(slug) {
    ApiService.setHeader()
    return ApiService.get('user', slug)
  },
  create(data) {
    ApiService.setHeader()
    return ApiService.post('user', data)
  },
  update(slug, data) {
    ApiService.setHeader()
    return ApiService.update('user', slug, data)
  },
  active(id) {
    ApiService.setHeader()
    return ApiService.update(`user/${id}/active`)
  },
  inactive(id) {
    ApiService.setHeader()
    return ApiService.update(`user/${id}/inactive`)
  },
  delete(slug) {
    ApiService.setHeader()
    return ApiService.delete(`user/${slug}`)
  }
}