import { RecoveryService } from "@/modules/recovery/recovery.service";
import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";

import { REQUEST_RECOVERY, CONFIRM_RECOVERY, RECOVERY } from "./actions.type";

import { SET_RECOVERY_CODE } from "./mutations.type";

export const actions = {
  async [REQUEST_RECOVERY]({ commit }, slug) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await RecoveryService.request_recovery(slug);
    commit(SET_RECOVERY_CODE, data);
    commit(CHANGE_PROGRESS, false);
  },
  async [CONFIRM_RECOVERY]({ commit }, slug) {
    commit(CHANGE_PROGRESS, true);
    await RecoveryService.confirm_recovery(slug);
    commit(CHANGE_PROGRESS, false);
  },
  async [RECOVERY]({ commit }, slug) {
    commit(CHANGE_PROGRESS, true);
    await RecoveryService.recovery(slug);
    commit(CHANGE_PROGRESS, false);
  }
};
