const userRoutes = [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/user/Users'),
  },
  {
    path: '/users/create',
    name: 'usersCreate',
    component: () => import('@/views/user/UserEdit'),
  },
  {
    path: '/users/:id/edit',
    name: 'usersEdit',
    component: () => import('@/views/user/UserEdit'),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return {id};
    }
  },
  {
    path: '/users/:id',
    name: 'user',
    component: () => import('@/views/user/User'),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return {id};
    }
  },
]
export default userRoutes