<template>
  <v-app>
    <router-view></router-view>
    <v-overlay :value="inProgress" z-index="999">
      <v-progress-circular indeterminate size="70" :width="6" color="purple"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.text }}
      <template v-slot:action="{attrs}">
        <v-btn text icon v-bind="attrs" @click.native="snackbar.snackbar = false" dark>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<style>
.v-application {
  font-family: "Montserrat", sans-serif !important;
}

.v-list-item-group .v-list-item--active {
  color: #6D59E8 !important;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: rgba(61, 17, 89, 0.87) !important;
}

.v-app-bar {
  position: fixed !important;
}

.v-data-table {
  border-top: thin solid rgba(0, 0, 0, 0.12);
  /*border-top: 1px solid black;*/
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: rgba(109, 89, 232, 0.1);
  color: #6D59E8;
}

.theme--light.v-data-table {
  color: #A3A3A3 !important;
}

.v-card {
  color: #6D59E8 !important;
}

.theme--light.v-icon {
  color: inherit !important;
}

h3 {
  color: #A3A3A3 !important;
}
.btn-lyndus{
  color:#3D1159 !important;
}
.btn-lyndus:hover {
  color: #6D59E8 !important;
}

.btn-del{
  color: #FF3816 !important;
}
.btn-inactive{
  color: #ff8c00 !important;
}
.btn-active{
  color: #008000 !important;
}
.btn-back {       
   background-color: #6D59E8 !important;
    border-color: #6D59E8 !important;
    color: #ffffff !important;
    
}
.btn-edit{
  background-color: #3A33E8 !important;
  border-color: #3A33E8 !important;
  color: #ffffff !important;
}
.btn-new{
  background-color: #3D1159 !important;
  border-color: #3D1159 !important;
  color: #ffffff !important;
}
.btn-cancel{
  background-color: #FF3816 !important;
  border-color: #FF3816 !important;
  color: #ffffff !important;
}
.btn-save{
  background-color: #008000 !important;
  border-color: #008000 !important;
  color: #ffffff !important;
}
.btn-secondary {
  color: #6D59E8 !important;
  border-color: #6D59E8 !important;
}
.text-data-visualization{
  font-size: 16px !important;
}
.upper input{
  text-transform: uppercase !important;
}

</style>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {},

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["inProgress", "snackbar"])
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
  },
  methods: {
    async accept() {
      this.showUpgradeUI = false
      await this.$workbox.messageSW({type: "SKIP_WAITING"});
    }
  },
};
</script>
