import Vue from "vue";
import VueRouter from "vue-router";

// bs routes
import bmRoutes from "@/router/bm.route";
import userRoutes from "@/router/user.route";
import profileRoutes from "@/router/profile.route";
import scheduleRoutes from "@/router/schedule.route";
import authRoutes from "@/router/auth.route";
import bannerRoute from "@/router/banner.route";
import serviceRoutes from "@/router/service.route";
import personsRoute from "./persons.route";
import recoverPass from "./recover.route";
import productRoute from "./product.route";
// client routes
import employeesRoute from "./employees.route";
import categoriesRoute from "./categories.route";
import benefitRoute from "./benefits.route";

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...recoverPass,
  {
    path: "/",
    name: "home",
    redirect: { name: 'employees' },
    component: () => import("../views/Home"),
    meta: {
      authRequired: true
    },
    children: [
      // bs
      ...bmRoutes,
      ...userRoutes,
      ...profileRoutes,
      ...scheduleRoutes,
      ...bannerRoute,
      ...personsRoute,
      ...productRoute,
      ...serviceRoutes,
      // client
      ...employeesRoute,
      ...categoriesRoute,
      ...benefitRoute
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
