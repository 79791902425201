import ApiService from "@/common/api/api.service";

export const RecoveryService = {
  request_recovery(data) {
    return ApiService.post("request-recovery", data);
  },
  confirm_recovery(data) {
    return ApiService.post("confirm-recovery", data);
  },
  recovery(data) {
    return ApiService.post("recovery", data);
  }
};

