import { EmployeeService } from "@/common/api/employee.service";
import {
  EMPLOYEES_GET,
  EMPLOYEE_GET,
  EMPLOYEE_CREATE,
  EMPLOYEE_UPDATE,
  EMPLOYEE_DELETE, EMPLOYEE_SEARCH, EMPLOYEE_ACTIVE, EMPLOYEE_INACTIVE,
} from "./actions/employees.type";
import {
  EMPLOYEES_SET,
  EMPLOYEE_SET,
  EMPLOYEES_RESET,
  EMPLOYEE_RESET,
  SET_EMPLOYEE_PAGINATION,
  SET_EMPLOYEE_ACTIVE,
} from "./mutations/employees.type";

import { CHANGE_PROGRESS } from "./mutations/mutations.type";

import { OnlyIDArrReverse } from "@/utils/arrays";
import {BENEFIT_RESET_STATE} from "@/store/mutations/benefit.type";

const initialState = () => {
  return {
    employee: {
      id: 0,
      name: "",
      cpf: "",
      categories: [],
    },

    confirmDeleteEmployee: false,

    employeePagination: {
      search: "",
      itemsPerPage: 10,
      orderBy: "",
      sortDesc: false,
    },

    employees: {
      active: true,
      activePage: 1,
      totalPages: 1,
      totalItems: 1,
      items: []
    }
  };
};

const state = initialState();

const getters = {
  employees: (state) => {
    return state.employees;
  },
  employeePagination: (state) => {
    return state.employeePagination
  },
  employee: (state) => {
    return state.employee;
  },
};

const mutations = {
  [EMPLOYEES_SET](state, data) {
    state.employees = data;
  },

  [EMPLOYEE_SET](state, data) {
    state.employee.categories = [];
    state.employee = data;
  },

  [SET_EMPLOYEE_PAGINATION](state, data) {
    state.employees.activePage = data.page
    state.employeePagination.itemsPerPage = data.itemsPerPage
    state.employeePagination.orderBy = data.orderBy
    state.employeePagination.sortDesc = data.sortDesc
  },
  [SET_EMPLOYEE_ACTIVE](state, active) {
    state.employee.active = active
  },

  [EMPLOYEES_RESET](state) {
    Object.assign(state.employees, initialState().employees);
  },
  [BENEFIT_RESET_STATE](state) {
    Object.assign(state.employees, initialState());
  },

  [EMPLOYEE_RESET](state) {
    Object.assign(state.employee, initialState().employee);
  },
};

const actions = {
  async [EMPLOYEES_GET]({ commit }) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await EmployeeService.list();
    commit(EMPLOYEES_SET, data);
    commit(CHANGE_PROGRESS, false);
  },

  async [EMPLOYEE_GET]({ commit }, id) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await EmployeeService.get(id);
    commit(EMPLOYEE_SET, data);
    
    commit(CHANGE_PROGRESS, false);
  },
  async [EMPLOYEE_SEARCH]({commit, state}) {

    commit(CHANGE_PROGRESS, true)
    const {data} = await EmployeeService.search(
      state.employees.activePage,
      state.employeePagination.itemsPerPage,
      state.employees.active,
      state.employeePagination.search,
      state.employeePagination.orderBy,
      state.employeePagination.sortDesc)
    commit(EMPLOYEES_SET, data)
    commit(CHANGE_PROGRESS, false)
  },
  async [EMPLOYEE_CREATE]({ state }) {
    let newEmployee = {
      name: state.employee.name,
      cpf: state.employee.cpf,
      categories: state.employee.categories,
    };

    return EmployeeService.create(newEmployee);
  },
  async [EMPLOYEE_UPDATE]({ state }) {
    let newEmployee = {
      id: state.employee.id,
      name: state.employee.name,
      cpf: state.employee.cpf,
      categories: OnlyIDArrReverse(state.employee.categories),
    };

    return EmployeeService.update(newEmployee);
  },
  [EMPLOYEE_ACTIVE](_, id) {
    return EmployeeService.active(id)
  },
  [EMPLOYEE_INACTIVE](_, id) {
    return EmployeeService.inactive(id)
  },
  async [EMPLOYEE_DELETE]({ state }) {
    return EmployeeService.delete(state.employee.id);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
