import Vue from "vue";
import Vuex from "vuex";

// bs
import auth from "./auth.module";
import user from "./user.module";
import weekDay from "./week-day.module";
// import bm from "./bm.module";
//import services from "./services.module";
import profile from "./profile.module";
import persons from "./persons.module";
// import schedule from "./schedule.module";
// client module
import category from "./category.module";
import benefits from "./benefit.module";
import employees from "./employees.module";
import recovery from "../modules/recovery/recovery.module";

import { CHANGE_PROGRESS, CHRANGE_SNACK } from "./mutations/mutations.type";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    inProgress: false,
    snackbar: {
      timeout: 9000,
      color: "",
      snackbar: false,
      text: ""
    },
    confirmDialog: {
      dialog: false,
      title: "",
      text: "",
      evento: () => {}
    }
  },
  getters: {
    inProgress: state => {
      return state.inProgress;
    },
    snackbar: state => {
      return state.snackbar;
    },
    confirmDialog: state => {
      return state.confirmDialog;
    }
  },
  mutations: {
    [CHANGE_PROGRESS](state, opt) {
      state.inProgress = opt;
    },
    [CHRANGE_SNACK](state, snack) {
      state.snackbar = snack;
    }
  },
  actions: {
    async Loading({ commit }, promise) {
      commit(CHANGE_PROGRESS, true);
      const data = await promise;
      commit(CHANGE_PROGRESS, false);
      return data;
    }
  },
  modules: {
    auth,
    user,
    //bm,
    weekDay,
    profile,
    persons,
    //schedule,
    // client modules
    category,
    benefits,
    employees,
    recovery
  }
});
