import ApiService from "@/common/api/api.service";

export const EmployeeService = {
  list() {
    ApiService.setHeader();
    return ApiService.get("employee");
  },
  get(slug) {
    ApiService.setHeader();
    return ApiService.get("employee", slug);
  },
  search(page=1, itemsPerPage=10, active=true, search="", orderBy = "", sortDesc = "") {
    ApiService.setHeader()
    return ApiService.get(`employee?page=${page}&itemsPerPage=${itemsPerPage}&active=${active}&search=${search}&orderBy=${orderBy}&sortDesc=${sortDesc}`)
  },
  create(data) {
    ApiService.setHeader();
    return ApiService.post("employee", data);
  },
  update(data) {
    ApiService.setHeader();
    return ApiService.put("employee", data);
  },
  importCSV(file) {
    ApiService.setHeader();
    let form = new FormData();
    form.append("file", file);
    return ApiService.postFile("employee/import", form);
  },
  active(id) {
    ApiService.setHeader()
    return ApiService.update(`employee/${id}/active`)
  },
  inactive(id) {
    ApiService.setHeader()
    return ApiService.update(`employee/${id}/inactive`)
  },
  delete(id) {
    ApiService.setHeader();
    return ApiService.delete(`employee/${id}`);
  },
};
