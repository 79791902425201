export const BENEFIT_CREATE = "benefitCreate";
export const BENEFIT_UPDATE = "benefitUpdate";
export const BENEFIT_EDIT = "benefitEdit";
export const BENEFIT_EDIT_USERS = "benefitEditUsers";
export const BENEFIT_GET = "benefitGet";
export const BENEFITS_GET = "benefitsGet";
export const BENEFIT_SEARCH = "benefitSearch"
export const BENEFIT_CONFIRM_PAYMENT = "benefitConfirmPayment"
export const BENEFIT_REQUEST_TICKET = "benefitRequestTicket"
export const BENEFIT_CANCEL = "benefitCancel"
export const BENEFIT_DOWNLOAD_TICKET = "benefitDownloadTicket"