import ApiService from "@/common/api/api.service";


export const CategoryService = {
  list() {
    return ApiService.AuthInstance().get("category/list");
  },
  search(page=1, itemsPerPage=10, active=true, search="", orderBy = "", sortDesc = "") {
    ApiService.setHeader()
    return ApiService.get(`category?page=${page}&itemsPerPage=${itemsPerPage}&active=${active}&search=${search}&orderBy=${orderBy}&sortDesc=${sortDesc}`)
  },
  get(slug) {
    return ApiService.AuthInstance().get(`category/${slug}`);
  },
  create(slug){
    return ApiService.AuthInstance().post(`category`,slug);
  },
  update(slug)
  { 
    return ApiService.AuthInstance().put(`category`,slug);
  },

  active(id) {
    ApiService.setHeader()
    return ApiService.update(`category/${id}/active`)
  },
  inactive(id) {
    ApiService.setHeader()
    return ApiService.update(`category/${id}/inactive`)
  },
  delete(id) {
    ApiService.setHeader()
    return ApiService.delete(`category/${id}`)
  }

};
