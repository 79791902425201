import Login from "@/views/auth/Login";

const authRoutes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {path: "/logout", name: 'logout', component: () => import("@/views/auth/Logout")},
    {
        path: '/EsqueciSenha',
        name: 'esqueciSenha',
        component: Login
    }
]
export default authRoutes