// Get/Get id
export const EMPLOYEES_GET = "employeesGet";
export const EMPLOYEE_GET = "employeeGet";
// Post
export const EMPLOYEE_CREATE = "employeeCreate";
export const EMPLOYEE_UPDATE = "employeeUpdate";
// delete
export const EMPLOYEE_DELETE = "employeeDelete";
export const EMPLOYEE_SEARCH = "employeeSearch";
export const EMPLOYEE_ACTIVE = 'productActive';
export const EMPLOYEE_INACTIVE = 'productInactive';
