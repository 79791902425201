/**
 * Recebe um array de objetos com uma propriedade chamada 'id' e retorna um array só com os ids
 * recursive
 * @param {[]} itemArr
 * @returns {[]} idsArr
 */
function OnlyIDArr(itemArr) {
  let idsArr = [];
  itemArr.forEach(item => {
    if (Array.isArray(item)) {
      idsArr = idsArr.concat(OnlyIDArr(item));
    } else {
      idsArr.push(item.id);
    }
  });
  return idsArr;
}

/**
 * Recebe um array de objetos com uma propriedade chamada 'id' e  um id
 * e retorna um objeto com o id passado
 * recursive
 * @param {[]} itemArr
 * @returns {any} item
 */
function FindItemWithRandomAlgorithm(itemArr, id) {
  let index = null,
    range = itemArr.length,
    random = Math.floor(Math.random() * range),
    randomList = [];

  while (random != index) {
    if (itemArr[random].id === id) {
      index = random;
      break;
    }
    randomList.push(random);
    while (randomList.includes(random)) {
      random = Math.floor(Math.random() * range);
    }
  }
  return !itemArr[index] ? null : itemArr[index];
}

/**
 *  Pega um array de ids e retorna um hashtable
 *  ex : [{id: 1, name: 'teste'}, {id: 2, name: 'teste2'}] -> {1: {id: 1, name: 'teste'}, 2: {id: 2, name: 'teste2'}}
 * @param {[]} arrItem
 * @returns {}
 */
function ArrayToHash(arrItem) {
  let hash = {};
  arrItem.forEach(item => {
    hash[item.id] = item;
  });
  return hash;
}

function OnlyIDArrReverse(itemArr) {
  if (!Array.isArray(itemArr)) {
    return [];
  }
  // console.log(itemArr);
  let idsArr = [];

  while (itemArr.length) {
    let item = itemArr.pop();
    if (item.id) {
      idsArr.push({ id: item.id });
    } else {
      idsArr.push({ id: item });
    }
  }
  // console.log(idsArr);
  return idsArr;
}

export {
  OnlyIDArr,
  FindItemWithRandomAlgorithm,
  ArrayToHash,
  OnlyIDArrReverse
};
