import {BenefitService} from "@/common/api/benefit.service";
import {
  BENEFITS_RESET,
  BENEFIT_RESET,
  SET_BENEFIT,
  SET_BENEFITS,
  SET_BENEFIT_CATEGORIES,
  SET_BENEFIT_ACTIVE,
  SET_BENEFIT_PAGINATION,
  BENEFIT_RESET_STATE
} from "@/store/mutations/benefit.type";
import {
  BENEFIT_CREATE,
  BENEFIT_GET,
  BENEFITS_GET,
  BENEFIT_UPDATE,
  BENEFIT_EDIT_USERS,
  BENEFIT_SEARCH, BENEFIT_CANCEL, BENEFIT_REQUEST_TICKET, BENEFIT_CONFIRM_PAYMENT, BENEFIT_DOWNLOAD_TICKET,
  // BENEFIT_ACTIVE,
  // BENEFIT_INACTIVE
} from "@/store/actions/benefit.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";

const initialState = () => {
  return {
    benefit: {
      id: 0,
      benefitStatus: "",
      benefitStatusID: 0,
      desc: "",
      value: 0.0,
      date: "",
      benefitUsers: [
        {
          id: 0,
          name: "",
          cpf: "",
          categories: [],
        },
      ],
      categories: [],
      allEmployees: false,
    },
    benefitPagination: {
      search: "",
      itemsPerPage: 10,
      orderBy: "",
      sortDesc: false,
      status: 1,
    },
    benefits: {
      activePage: 1,
      totalPages: 1,
      totalItems: 1,
      items: []
    },
  };
};

export const state = initialState();

const getters = {
  benefits: (state) => {
    return state.benefits;
  },
  benefit: (state) => {
    return state.benefit;
  },
  benefitPagination: (state) => {
    return state.benefitPagination
  }
};

const mutations = {
  [SET_BENEFITS](state, data) {
    state.benefits = data;
  },
  [SET_BENEFIT](state, data) {
    state.benefit = data;
  },
  [SET_BENEFIT_CATEGORIES](state, data) {
    state.benefit.categories = data;
  },
  [SET_BENEFIT_PAGINATION](state, data) {
    state.benefits.activePage = data.page
    state.benefitPagination.itemsPerPage = data.itemsPerPage
    state.benefitPagination.orderBy = data.orderBy
    state.benefitPagination.sortDesc = data.sortDesc
  },
  [SET_BENEFIT_ACTIVE](state, data) {
    state.benefit.active = data;
  },
  [BENEFITS_RESET](state) {
    Object.assign(state.benefits, initialState().benefits);
  },
  [BENEFIT_RESET]() {
    Object.assign(state.benefit, initialState().benefit);
  },
  [BENEFIT_RESET_STATE]() {
    Object.assign(state.benefit, initialState().benefit);
  },
  resetDesc(state) {
    state.benefit.desc = "";
  },
};

const actions = {
  async [BENEFITS_GET]({dispatch, commit}) {
    const {data} = await dispatch("Loading", BenefitService.list());
    commit(SET_BENEFITS, data);
    commit(CHANGE_PROGRESS, false);
  },
  async [BENEFIT_SEARCH]({commit, state}) {
    commit(CHANGE_PROGRESS, true)
    const {data} = await BenefitService.search(
      state.benefits.activePage,
      state.benefitPagination.itemsPerPage,
      state.benefitPagination.status,
      state.benefitPagination.search,
      state.benefitPagination.orderBy,
      state.benefitPagination.sortDesc)
    commit(SET_BENEFITS, data)
    commit(CHANGE_PROGRESS, false)
  },
  async [BENEFIT_GET]({dispatch, commit}, id) {
    const {data} = await dispatch("Loading", BenefitService.get(id));
    commit(SET_BENEFIT, data);
    commit(CHANGE_PROGRESS, false);
  },
  async [BENEFIT_CREATE]({state}) {
    let benefit = {
      desc: state.benefit.desc,
      allEmployees: state.benefit.allEmployees,
      categories: state.benefit.categories
    }
    return BenefitService.create(benefit);
  },
  async [BENEFIT_UPDATE]({state}) {
    let benefit = {
      id: state.benefit.id,
      desc: state.benefit.desc
    }
    return BenefitService.update(benefit);
  },

  async [BENEFIT_CANCEL](_, id) {
    let data = {
      id: id
    }
    return BenefitService.cancel(data);
  },
  async [BENEFIT_REQUEST_TICKET](_, id) {
    let data = {
      id: id
    }
    return BenefitService.requestTicket(data);
  },
  async [BENEFIT_CONFIRM_PAYMENT](_, id) {
    let data = {
      id: id
    }
    return BenefitService.confirmPayment(data);
  },
  async [BENEFIT_DOWNLOAD_TICKET](_, id) {
    return BenefitService.downloadTicket(id);
  },


  async [BENEFIT_EDIT_USERS]({state}, data) {
    return BenefitService.updateUser(
      state.benefit.id,
      data
    )
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
