import ApiService from "@/common/api/api.service";

export const BenefitService = {
  list() {
    ApiService.setHeader();
    return ApiService.get("benefit");
  },
  get(slug) {
    ApiService.setHeader();
    return ApiService.get("benefit", slug);
  },
  search(page=1, itemsPerPage=10, status=0, search="", orderBy = "", sortDesc = "") {
    ApiService.setHeader()
    return ApiService.get(`benefit?page=${page}&itemsPerPage=${itemsPerPage}&status=${status}&search=${search}&orderBy=${orderBy}&sortDesc=${sortDesc}`)
  },
  create(data) {
    ApiService.setHeader();
    return ApiService.post("benefit", data);
  },
  update(data) {
    ApiService.setHeader();
    return ApiService.update("benefit","" , data);
  },

  cancel(data) {
    ApiService.setHeader();
    return ApiService.update("benefit/cancel","" , data);
  },
  requestTicket(data) {
    ApiService.setHeader();
    return ApiService.update("benefit/requestTicket","" , data);
  },
  confirmPayment(data) {
    ApiService.setHeader();
    return ApiService.update("benefit/confirmPayment","" , data);
  },
  downloadTicket(id) {
    ApiService.setHeader();
    return ApiService.getFile(`benefit/${id}/file`);
  },


  updateUser(id, data) {
    ApiService.setHeader();
    return ApiService.update(`benefit/${id}/benefit-user`, "", data);
  }
};
