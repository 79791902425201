import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import { API_URL } from "@/common/config";
import router from "@/router";

const ApiServiceAbstract = {
  init() {
    Vue.use(VueAxios, axios);

    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.interceptors.response.use(
      response => {
        // intercept the global error
        return response;
      },
      function(error) {
        if (error.response.status === 401) {
          if (
            window.location.pathname !== "/login" ||
            window.location.pathname !== "/login"
          )
            router.push({ name: "login" }); //window.location.href = '/login';

          return;
        }
        // Do something with response error
        return Promise.reject(error);
      }
    );
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(` ApiService ${error}`);
    });
  },
  getFile(resource, slug = "") {
    let url = "";
    slug ? (url = `${resource}/${slug}`) : (url = `${resource}`);
    return Vue.axios
      .get(url, {
        responseType: "arraybuffer"
      })
      .catch(error => {
        throw new Error(` ApiService ${error}`);
      });
  },
  async getImageBlob(image_url) {
    return await Vue.axios
      .get(image_url, { responseType: "arraybuffer" })
      .then(response => {
        return `data:${response.headers[
          "content-type"
        ].toLowerCase()};base64,${new Buffer(response.data, "binary").toString(
          "base64"
        )}`;
      })
      .catch(error => {
        throw new Error(` ApiService ${error}`);
      });
  },
  get(resource, slug = "") {
    let url = "";
    slug ? (url = `${resource}/${slug}`) : (url = `${resource}`);
    return Vue.axios.get(url).catch(error => {
      console.log(error);
      // throw new Error(` ApiService ${error}`)
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },
  postFile(resource, data) {
    return Vue.axios.post(resource, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  update(resource, slug, params) {
    let url = "";
    slug ? (url = `${resource}/${slug}`) : (url = `${resource}`);
    return Vue.axios.put(url, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(` ApiService ${error}`);
    });
  }
};

const ApiService = {
  ...ApiServiceAbstract,
 AuthInstance: function() {ApiServiceAbstract.setHeader(); return this}
}
export default ApiService;
