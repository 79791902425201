const employeesRoute = [
  {
    path: "/employees",
    name: "employees",
    component: () => import("@/views/employees/Employees")
  },
  {
    path: "/employees/create",
    name: "employeesCreate",
    component: () => import("@/views/employees/EmployeesCreate")
  },
  {
    path: "/employees/:id",
    name: "employee",
    component: () => import("@/views/employees/Employee"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
  {
    path: "/employees/:id/edit",
    name: "employeeEdit",
    component: () => import("@/views/employees/EmployeesCreate"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  }
];

export default employeesRoute;
