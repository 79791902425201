import {SET_REQUEST,
SET_CONFIRM,
SET_RECOVERY,
SET_RECOVERY_CODE} from './mutations.type'

export const mutations = {
    [SET_REQUEST](state, data) {
        state.request = data;
    },
    [SET_CONFIRM](state, data) {
        state.confirm = data;
    },
    [SET_RECOVERY](state,data) {
        state.recovery = data;
    },
    [SET_RECOVERY_CODE](state,data) {
        state.recoveryCode     = data;
        state.recovery.key     = data.key;
        state.recoveryCode.key = data.key;
        state.confirm.key      = data.key;

    }
};