
import {actions} from "./recovery.actions";
import {state} from "./recovery.state";
import {mutations} from "./recovery.mutations";
import {getters} from "./recovery.getters";


export default {
  state,
  actions,
  mutations,
  getters
};